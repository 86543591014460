<style scoped>
.top {
  height: 50px;
  background: #000;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-shadow: inset 0 0 10px 0 hsl(0deg 0% 100% / 41%);
}
</style>
<template>
  <div class="containers">
    <div class="top">
      <span @click="$router.back()">
        <svg
          t="1701932820789"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="12453"
          width="32"
          height="32"
        >
          <path
            d="M510.9 60.7c-245.6 0-446.7 199.8-446.7 446.7C64.2 753 263.9 954 510.8 954s446.6-199.7 446.6-446.6c0.1-245.6-199.6-446.7-446.5-446.7zM760.5 694c-0.1 6.3-5.3 11.4-11.7 11.4-5.6 0-10.3-3.9-11.4-9.3-0.2-0.7-7.8-35.2-40.4-69.1-41.5-43.2-105.8-66.9-186.4-69v93.8c0 4.4-2.5 8.3-6.4 10.3-3.9 2-8.6 1.6-12.2-0.9L268.2 500.7c-1-0.7-1.9-1.6-2.6-2.6-3.7-5.2-2.5-12.3 2.6-16L492 321c3.6-2.6 8.3-2.9 12.2-1 3.9 2 6.3 5.9 6.4 10.3v99.6c23.2 0.9 46.3 4.4 68.7 10.3 46.4 12.2 85 33.4 114.6 63.2 45.6 45.6 68 109.8 66.6 190.6z"
            p-id="12454"
            fill="#ffffff"
          ></path>
        </svg>
      </span>
    </div>
    <live-hw-rtc-cmp
      :roomId="roomId"
      style="width: 100%; height: calc(100vh -50px)"
    >
    </live-hw-rtc-cmp>
  </div>
</template>


<script>
/* eslint-disable */

import liveHwRtcCmp from "@/widgets/liveHwRtcCmp";

export default {
  name: "Test",
  components: {
    liveHwRtcCmp,
  },
  data() {
    return {
      roomId: "1234567890qwertyuioplkjhgfdsazxcvbnm",
    };
  },
  methods: {},
  created() {
    // const query = this.$route.query;
    // if (query.roomId) {
    //   this.roomId = query.roomId;
    // }
  },
  async destroyed() {},
};
</script>
